<template>
  <div class="overflow-hidden h-[410px] relative group">
    <div id="pro-card" class="h-full flex flex-col grow">
      <ClientOnly>
        <div
          id="wishlist"
          class="absolute bg-black bg-opacity-60 top-5px right-5.5 w-7 h-7 align-middle justify-center cursor-pointer rounded-2 p-1 z-30"
          @click="toggleWishlist"
        >
          <i
            v-if="pro.isWishlisted"
            class="object-cover icon-heart-filled text-20 leading-5 mx-auto text-grayscale-96"
          />
          <i
            v-else
            class="object-cover icon-bookmark text-20 leading-5 text-grayscale-96"
          />
        </div>
      </ClientOnly>
      <div
        :to="`/${pro.uniqueName}`"
        @click="openProProfile"
        class="h-full flex flex-col grow cursor-pointer relative"
      >
        <router-link
          :to="`/${pro.uniqueName}`"
          @click="openProProfile"
          class="h-full w-full absolute z-20 text-transparent"
          >{{ getDisplayName(pro) }}</router-link
        >
        <image-kit
          :path="pro.profilePicture || 'placeholder.png'"
          width="200"
          height="260"
          :alt="`Connect ${pro.firstName} ${pro.lastName}`"
          class="w-full h-[260px] flex-shrink-0 object-cover rounded-4"
        />
        <div
          class="hidden group-hover:block w-full h-[260px] flex-shrink-0 object-cover rounded-4 absolute top-0 left-0 hover-gradient"
        >
          <p
            v-if="!(soldOutCalls && soldOutFeedbacks)"
            class="hidden group-hover:block bg-accent-green bg-opacity-10 border border-accent-green border-opacity-50 rounded-3 text-accent-green absolute top-[238px] left-2 md:left-5.5 z-10 align-middle justify-center cursor-pointer px-2 py-2px font-semibold text-[11px] leading-[15px]"
          >
            Book a 1-1
          </p>
        </div>
        <div class="flex flex-col gap-2 flex-1 h-32 w-full">
          <div class="pt-4 text-left">
            <p class="pb-1 text-15 text-grayscale-96 font-bold">
              {{ pro.title || pro.firstName + " " + pro.lastName
              }}<i
                class="ml-1 my-auto inline-block icon-star-checked-filled text-12 leading-3 cursor-default text-accent-orange"
              />
            </p>
            <p class="text-grayscale-96 text-13 line-clamp-2 leading-120">
              {{ pro.worksAt }}
            </p>
            <div v-if="pro.connections?.length" class="mt-2">
              <p class="text-grayscale-60 text-[10px] leading-140 mb-0.5">
                Worked with
              </p>
              <p class="text-grayscale-80 text-xs leading-120 truncate">
                {{ pro.connections.join(", ") }}
              </p>
            </div>
          </div>
          <div class="flex items-center font-semibold">
            <div
              v-if="cheapest"
              class="text-xs text-grayscale-96 leading-[15px]"
            >
              ${{ cheapest }}+
            </div>
            <div
              v-if="soldOutCalls && soldOutFeedbacks"
              class="ml-4 text-[11px] leading-[15px] text-helpers-validation"
            >
              Fully Booked
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, PropType, ref, watch } from "vue";
import { User } from "@/types/user";
import { useWishlistStore } from "@/store/wishlist";
import { ProductType } from "@/types/product";
import ImageKit from "@/components/imagekit/ImageKit.vue";
import AnalyticsService from "@/services/analytics-service";
import config from "@/config";
import { useAuthenticationStore } from "@/store/authentication";
import { AuthActionTypes } from "@/types/authentication";
import { getDisplayName } from "@/utils/display-name";

const props = defineProps({
  pro: {
    type: Object as PropType<User>,
    required: true,
  },
});

const wishlistStore = useWishlistStore();
const analyticsService = new AnalyticsService("analytics");
const authStore = useAuthenticationStore();

const pro = ref(props.pro);
const feedbackPrice = ref("");
const callPrice = ref("");
const cheapest = ref("");
const soldOutFeedbacks = ref(false);
const soldOutCalls = ref(false);

const setMinPrices = () => {
  const feedbackPrices =
    pro.value.products
      ?.map((product) => {
        if (
          [ProductType.video, ProductType.audio, ProductType.written].includes(
            product.type,
          ) &&
          product.isAvailable
        ) {
          return product.price.toString();
        }
      })
      .filter((price): price is string => !!price)
      .sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      }) || [];
  if (feedbackPrices) {
    feedbackPrice.value = feedbackPrices[0]!;
  }

  const callPrices =
    pro.value.products
      ?.map((product) => {
        if (
          [ProductType.shortCall, ProductType.longCall].includes(
            product.type,
          ) &&
          product.isAvailable
        )
          return product.price.toString();
      })
      .filter((price): price is string => !!price)
      .sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      }) || [];
  if (callPrices) {
    callPrice.value = callPrices[0]!;
  }

  const prices =
    pro.value.products
      ?.map((product) => {
        if (product.type !== ProductType.free && product.isAvailable)
          return product.price.toString();
      })
      .filter((price): price is string => !!price)
      .sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      }) || [];

  cheapest.value = prices[0];
};

const openProProfile = () => {
  analyticsService.trackEvent("Pro Clicked", {
    proId: props.pro._id,
    proName: props.pro.uniqueName,
    url: `${config("baseUrl")}/${props.pro.uniqueName}`,
  });
};

watch(
  () => props.pro,
  () => {
    pro.value = props.pro;
    setMinPrices();
  },
);

async function toggleWishlist() {
  if (!authStore.authUser.authToken) {
    authStore.setModalState({
      isModalVisible: true,
      defaultActionType: AuthActionTypes.login,
    });
    return;
  }
  if (!pro.value.isWishlisted) {
    pro.value.isWishlisted = true;
    try {
      await wishlistStore.addToWishlist(pro.value._id);
    } catch (error) {
      console.log(error);
    }

    window.analytics &&
      window.analytics.track("Pro Wishlisted", {
        proId: pro.value._id,
        proName: pro.value.firstName + " " + pro.value.lastName,
      });
  } else {
    pro.value.isWishlisted = false;
    await wishlistStore.removeFromWishlist(pro.value._id);
  }
}

onMounted(() => {
  pro.value = props.pro;
  setMinPrices();
  soldOutFeedbacks.value = Boolean(
    pro.value?.feedBackCount !== undefined &&
      pro.value?.feedBacksPerWeek !== undefined &&
      pro.value?.feedBackCount >= pro.value?.feedBacksPerWeek,
  );
  soldOutCalls.value = Boolean(
    pro.value?.consultationCallCount !== undefined &&
      pro.value?.consultationCallsPerWeek !== undefined &&
      pro.value?.consultationCallCount >= pro.value?.consultationCallsPerWeek,
  );
});
</script>
<style scoped>
@media screen and (min-width: 768px) {
  #wishlist {
    display: none;
  }

  #pro-card:hover #wishlist {
    display: block;
  }
}

.hover-gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
}
</style>
